import mixpanel from "mixpanel-browser";
import {getCurrentUser} from "aws-amplify/auth";

export const MixMsDebounceWait = 5000;

export enum BaseActions {
  pageLoad = 'PAGE_LOAD',
  fieldsEdit = 'FIELDS_EDIT',
  openModal = 'OPEN_MODAL',
  closeModal = 'CLOSE_MODAL',
  submitClick = 'SUBMIT_CLICK',
  navigateAway = 'NAVIGATE_AWAY',
}

export async function optInMixPanel() {
  if (mixpanel.has_opted_in_tracking()) {
    return;
  }

  mixpanel.opt_in_tracking();
  mixpanel.register({
    "isLoggedIn": await getCurrentUser().catch(err => undefined) !== undefined,
  })
}

export function optOutMixPanel() {
  mixpanel.opt_out_tracking();
}

export function sendMixEvent(eventName: string, event: any) {
  if (mixpanel.has_opted_in_tracking()) {
    mixpanel.track(eventName, event);
  }
}

export function getMixPanelId() {
  return mixpanel.get_distinct_id();
}