import {Route} from "react-router-dom";
import React from "react";


export function toRoute(routeElement, index): JSX.Element {
  if (routeElement.nestedRoutes) {
    return <Route element={routeElement.element}
                  path={routeElement.route}
                  index={routeElement.index}
                  key={index}>
      {routeElement.nestedRoutes.map(toRoute)}
    </Route>
  }

  return <Route element={routeElement.element}
                key={index}
                index={routeElement.index}
                path={routeElement.route}/>
}
