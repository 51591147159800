import {fetchPostResponse} from "./fetchUtils";
import UAParser from "ua-parser-js";
import debounce from "lodash/debounce";
import {stringify} from "flatted";
import {AuthError, getCurrentUser} from "aws-amplify/auth";
import {AxiosError, isAxiosError} from "axios";

export function logErrorToBackend(error: Error, info: any, pathName: string): Promise<any> {
  return getCurrentUser()
    .then(user => {
      return debouncedPostError(error, user.username, info, pathName);
    }).catch(() => {
      return debouncedPostError(error, "anonymous", info, pathName)
    })
}

const safeErrors = ["Getting logged in convos for", "No commissions found for userId:", "Error during service worker registration"]

const version = "0.1.12"

function postError(error: Error, userName: string, info: any, pathName: string) {
  // discord limits each field to 1024 chars, the rest of the error message can be about 250ish chars, so setting this to 600 allows about 274 chars of breathing room for the
  // request https://stackoverflow.com/questions/53935198/in-my-discord-webhook-i-am-getting-the-error-embeds-0
  const uaParserRes = new UAParser().getResult();
  const userAgent = `${uaParserRes.browser.name} ${uaParserRes.browser.version} ${uaParserRes.os.name} ${uaParserRes.os.version} ${uaParserRes.engine.name}`;
  const stack = stringify(info).substring(0, 600);
  const errorJson = (error.message || error) + " STACK: " + stack + " v: " + version + " UA_AGENT: " + userAgent + " PATH: " + pathName;


  if (safeErrors.some(safeError => errorJson.includes(safeError))) {
    return
  }

  return fetchPostResponse("logging", {
    user: userName,
    error: errorJson
  }).catch(error => {
    console.error(error)
  })
}

const debouncedPostError = debounce(postError, 5000, {trailing: true});

// from https://stackoverflow.com/a/42755876/6523738
export class RethrownError<T> extends AxiosError<T> {
  private original_error: AxiosError<T> | Error;
  private stack_before_rethrow: string | undefined;

  constructor(message: string, error: AxiosError<T> | Error) {
    if (isAxiosError(error)) {
      super(message, error.code, error.config, error.request, error.response)
    } else {
      super(message)
    }
    this.name = this.constructor.name
    if (!error) throw new Error('RethrownError requires a message and error')
    this.original_error = error
    this.stack_before_rethrow = this.stack
    const message_lines = (this.message.match(/\n/g) || []).length + 1
    // @ts-ignore
    this.stack = this.stack.split('\n').slice(0, message_lines + 1).join('\n') + '\n' +
      error.stack
  }
}

const notAuthorizedException = "NotAuthorizedException";

export function isUnauthorizedError(error) {
  if (error) {
    return typeof error !== "string" && error.name === notAuthorizedException;
  }
  return false;

}

export function reloadIfUnAuthorized(error: AuthError | any) {
  if (error) {
    if (typeof error !== "string" && error.name === notAuthorizedException) {
      window.location.reload();
      return;
    }

    throw error;
  }

  throw error;
}

export function isServerError(error: AxiosError) {
  return error.status && error.status >= 500;
}